import { IRIS_DEROGATION_70_80, IRIS_DEROGATION_70_80_CRUCERO } from "@constants/constants";
import { checkMinimunAge } from "@helpers/utils";
import { useSelector } from "react-redux";

const usePriceAndAddiontalFeesCalculatorIris = ({
  totalTravellers,
  watch,
  variant,
  isInsuredPolicyHolder,
  priceWithoutAdditionalFees,
  discountPercentage,
  currency,
  departureDate
}: {
  totalTravellers: number;
  isInsuredPolicyHolder: boolean;
  watch: Function;
  variant?: string;
  priceWithoutAdditionalFees?: any,
  discountPercentage?: any,
  currency?:any,
  departureDate?:any

}) => {

  const travellers = watch("travellers");
  const policyHolderBirthdate = watch("dob");
  const { irisExtraAll }: any = useSelector<any>(
    ({ Travelfine }) => Travelfine.product
  );
  const extra = irisExtraAll?.filter((extra: any) => extra?.derogation === IRIS_DEROGATION_70_80 || extra?.derogation === IRIS_DEROGATION_70_80_CRUCERO)[0];
  const priceOriginal: any =  Number.parseFloat(priceWithoutAdditionalFees) / totalTravellers;
  const priceObject = extra?.productPricesCurrencies?.find((currencyObj: any) => currencyObj[currency?.value] !== undefined);
  const priceExtra = priceObject ? parseFloat(priceObject[currency?.value]) : (extra?.productPrices ?? 0);
  const infoExtra = extra?.title ?? '';

  function getNumberOfElderIris() {
    let output = 0;
    if (irisExtraAll.length > 0) {
      if (
        isInsuredPolicyHolder &&
        checkMinimunAge(policyHolderBirthdate, departureDate)
      ) {
        output += 1;
      }


      travellers.forEach((traveller: any) => {

        if (
          checkMinimunAge(traveller.dob, departureDate)
        ) {
          output += 1;
        }
      });
    }
    return output;
  }

  const elderCount = getNumberOfElderIris();
  const totalPriceBeforeDiscount = getTotalPrice();
  const totalPriceAfterDiscount =Number(getFinalPriceAfterDiscount(totalPriceBeforeDiscount).toFixed(2));
  const additionalFeeDueElders = totalPriceBeforeDiscount - priceWithoutAdditionalFees;
  const priceNetoExtra:number = Number(((extra?.irisPriceObj?.Precio_Neto / totalTravellers) * elderCount)?.toFixed(2));
  const totalPriceAfterWithoutDiscount = getFinalPriceAfterDiscount(totalPriceBeforeDiscount,true);

  function getTotalPrice() {
    let totalTraveller = totalTravellers - elderCount;
    const totalPrice = (elderCount * priceExtra) + (totalTraveller * priceOriginal);
    return Number(totalPrice?.toFixed(2));
  }

 
  function getFinalPriceAfterDiscount(total: any, isWithoutDiscount: boolean = false) {
    let totalTraveller = totalTravellers - elderCount;
    const totalPrice = (elderCount * priceExtra) + (totalTraveller * priceOriginal);
    let output = totalPrice;
    if (discountPercentage && !isWithoutDiscount) {
      output -= (discountPercentage * output) / 100;
    }
    return output;
  }


  return {
    elderCount,
    totalPriceBeforeDiscount,
    totalPriceAfterDiscount,
    totalPriceAfterWithoutDiscount,
    additionalFeeDueElders,
    infoExtra,
    isExtraDerogation: elderCount > 0,
    sobrePrimaExtraIris: extra ? {
      ...extra?.extra
    } : null,
    priceNetoExtra
  };
};

export { usePriceAndAddiontalFeesCalculatorIris };