
import { useEffect, useState } from "react";
import { Outlet } from "react-router";
import ReactGA from "react-ga4";
import "react-toastify/dist/ReactToastify.css";
import "./App.css";
import { useDispatch, useSelector } from "react-redux";
import { getCodeByKey, resetCode } from "@store/code/CodeActions";
import { Loader } from "@components";
import Cookies from "js-cookie";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  CODE_RASTREATOR,
  CODE_GLOBAL_FINE_HOME_PRO,
  CODE_GLOBAL_FINE_PRO,
  CODE_TRAVEL_FINE_HOME_PRO,
  CODE_HOME_MX,
  GLOBAL_FINE,
  COOKIE_EXPIRE
} from "@constants/constants";
import Theme from "Theme";
import { ToastContainer } from "react-toastify";

const App = () => {
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [countryCode, setCountryCode] = useState<string | null>(null);

  const { brandColor, code: codeSearch }: any = useSelector<any>(({ Travelfine }) => Travelfine.code);

  const hostname = window.location.hostname;
  const isGlobalfine = hostname.includes("globalfine");

  if (process.env.REACT_APP_NODE_ENV === "production" && !isGlobalfine) {
    ReactGA.initialize("G-10JJ2GXWXR");
    ReactGA.send("pageview");
  }

  useEffect(() => {
    if (process.env.REACT_APP_NODE_ENV === "production") {
      (function (c: any, l: any, a: any, r: any, i: any, t: any, y: any) {
        c[a] =
          c[a] ||
          function () {
            (c[a].q = c[a].q || []).push(arguments);
          };
        t = l.createElement(r);
        t.async = 1;
        t.src = "https://www.clarity.ms/tag/" + i;
        y = l.getElementsByTagName(r)[0];
        y.parentNode.insertBefore(t, y);
      })(
        window,
        document,
        "clarity",
        "script",
        "g5cgkk1sxs",
        undefined,
        undefined
      );
    }
  }, []);


  let code = searchParams.get("code");

  useEffect(() => {    
    const detectCountryCode = () => {
      const language = navigator.language;
      const countryFromLanguage = language.split("-")[1];
      const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      const detectedCountry = countryFromLanguage || timeZone || "US";
      setCountryCode(detectedCountry);
      Cookies.set("countryCode", detectedCountry, { expires: COOKIE_EXPIRE });
    };

    const storedCountryCode = Cookies.get("countryCode") || "";
    if (!storedCountryCode) {
      detectCountryCode();
    } else {
      setCountryCode(storedCountryCode);
    }
    
    const codeCookieValue = Cookies.get("code");
    const urlHome = window.location.host;

    const fetchCode = async (codeKey: string) => {
      await dispatch(getCodeByKey({ codeKey }));
      setLoading(false);
    };

    if (code) {
      if (!urlHome.includes(GLOBAL_FINE.code)) {
        if (!codeCookieValue && code === CODE_GLOBAL_FINE_PRO) {
          Cookies.set("code", code, { expires: COOKIE_EXPIRE });
        }
      }

      if (!codeCookieValue && code === CODE_RASTREATOR) {
        Cookies.set("code", code, { expires: COOKIE_EXPIRE });
      }

      if (codeSearch?.isCookie) {
        Cookies.set("code", code, { expires: COOKIE_EXPIRE });
      }

      fetchCode(code);
    } else if (codeCookieValue) {
      let navigationPath = `/?code=${codeCookieValue}`;
      if (window?.location?.search?.length > 0) {
        navigationPath = `/${window.location.search}&code=${codeCookieValue}`;
      }
      navigate(navigationPath, { replace: true });
    } else if (countryCode) {
      if (countryCode.toUpperCase().includes("MX")) {
        let codeValue = CODE_HOME_MX.codeTravelfine;
        if (urlHome.includes(GLOBAL_FINE.code)) {
          codeValue = CODE_HOME_MX.codeGlabalFine;
        }
        Cookies.set("code", codeValue, { expires: COOKIE_EXPIRE });
        let navigationPath = `/?code=${codeValue}`;
        if (window?.location?.search?.length > 0) {
          navigationPath = `/${window.location.search}&code=${codeValue}`;
        }
        navigate(navigationPath, { replace: true });
      }
    } else if (urlHome.includes(GLOBAL_FINE.code)) {
      Cookies.set("code", CODE_GLOBAL_FINE_HOME_PRO, { expires: COOKIE_EXPIRE });
      fetchCode(CODE_GLOBAL_FINE_HOME_PRO);
    } else if (urlHome.includes("travelfine")) {
      fetchCode(CODE_TRAVEL_FINE_HOME_PRO);
    } else {
      dispatch(resetCode({}));
      setLoading(false);
    }
  }, [code, dispatch, navigate]);

  return (
    <div className="App">
      <ToastContainer />
      <Theme>
        {brandColor && <Outlet />}
      </Theme>
    </div>
  );
};

export default App;
