import { TextField } from "@mui/material";
import React, { useState } from "react";
import { LocalizationProvider } from "@mui/lab";
import DateAdapter from "@mui/lab/AdapterDateFns";
import { makeStyles } from "@mui/styles";
import esLocale from "date-fns/locale/es";
import enLocale from "date-fns/locale/en-US";
import {
  DateRangePicker as MaterialDateRangePicker,
  DatePicker as MaterialDatePicker,
} from "@mui/lab";
import { Controller } from "react-hook-form";
import { isValid } from "date-fns";
interface DatePickerProps {
  name: string;
  label?: string;
  control: any;
  error?: boolean;
  errorMessage?: string;
  required?: boolean;
  fullWidth?: boolean;
  className?: string;
  color?:
    | "primary"
    | "secondary"
    | "success"
    | "error"
    | "info"
    | "warning"
    | undefined;
  size?: "small" | "medium" | undefined;
  variant?: "outlined" | "standard" | "filled" | undefined;
  startText?: string;
  endText?: string;
  okText?: string;
  cancelText?: string;
  inputFormat?: string;
  minDate?: Date;
  maxDate?: Date;
  type?: any;
  labelOutside?: boolean;
  disabled?: boolean;
  value?: any;
  onChange?: any;
  disableTextField?: boolean;
  smallTextsInBigScreens?: boolean;
}

const useStyles: any = makeStyles((theme: any) => ({
  root: {
    "& .MuiFilledInput-root": {
      background: "white !important",
    },
  },
}));

const locale = process.env.REACT_APP_LANGUAGE !== "en" ? esLocale : enLocale;

const DatePicker: React.FC<DatePickerProps> = (props) => {
  const {
    name,
    control,
    error,
    errorMessage,
    required,
    fullWidth,
    className,
    color,
    variant,
    size = "medium",
    startText,
    endText,
    okText = "ok",
    cancelText = "cancel",
    inputFormat = "dd/MM/yyyy",
    minDate,
    maxDate,
    type,
    label,
    labelOutside,
    disabled,
    onChange,
    disableTextField,
    value,
    smallTextsInBigScreens,
  } = props;

  const classes = useStyles();

  const [open, setOpen] = useState(false);

  return (
    <div className={`flex w-full justify-center ${className}`}>
      <LocalizationProvider dateAdapter={DateAdapter} locale={locale}>
        <Controller
          name={name}
          control={control}
          render={({ field }) =>
            type === "dateRange" ? (
              <MaterialDateRangePicker
                {...field}
                startText={startText}
                endText={endText}
                okText={okText}
                cancelText={cancelText}
                inputFormat={inputFormat}
                minDate={minDate}
                maxDate={maxDate}
                className="w-min"
                renderInput={(startProps, endProps) => (
                  <div className="flex">
                    <TextField
                      {...startProps}
                      className={`${classes.root} capitalize`}
                      error={error}
                      helperText={errorMessage}
                      required={required}
                      variant={variant}
                      size={size ? size : "medium"}
                      fullWidth={fullWidth}
                      color={color}
                    />
                    <TextField
                      {...endProps}
                      className={`${classes.root} capitalize  ml-2 `}
                      error={error}
                      helperText={errorMessage}
                      required={required}
                      variant={variant}
                      size={size ? size : "medium"}
                      fullWidth={fullWidth}
                      color={color}
                    />
                  </div>
                )}
              />
            ) : (
              <MaterialDatePicker
                {...field}
                value={value ?? field.value}
                onChange={(date) => {
                  if (!isValid(date)) {
                    date = minDate ? minDate : new Date();
                  }
                  if (minDate && date < minDate) {
                    date = minDate;
                  }
                  onChange ? onChange(date, field) : field.onChange(date);
                }}
                open={open}
                onOpen={() => setOpen(true)}
                onClose={() => setOpen(false)}
                okText={okText}
                cancelText={cancelText}
                inputFormat={inputFormat}
                minDate={minDate}
                maxDate={maxDate}
                disabled={disabled}
                disableMaskedInput
                renderInput={(params: any) => (
                  <div className="flex flex-col w-full">
                    {labelOutside && (
                      <label className="text-sm font-medium whitespace-nowrap">
                        {label}
                        {required && "*"}
                      </label>
                    )}
                    <TextField
                      {...params}
                      inputProps={{
                        ...params.inputProps,
                        onChange: (event) => {
                          disableTextField
                            ? event?.preventDefault()
                            : params?.inputProps?.onChange(event);
                        },
                      }}
                      InputProps={
                        smallTextsInBigScreens
                          ? { className: "lg:text-sm" }
                          : undefined
                      }
                      onClick={(e) => setOpen(true)}
                      value={field.value}
                      className={`${classes.root}`}
                      error={error}
                      helperText={errorMessage}
                      required={required}
                      variant={variant}
                      size={size ? size : "medium"}
                      fullWidth={fullWidth}
                      color={color}
                      label={!labelOutside && label}
                    />
                  </div>
                )}
              />
            )
          }
        />
      </LocalizationProvider>
    </div>
  );
};

export default DatePicker;
