import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
// import { useFieldArray } from "react-hook-form";
import { Paper, Tooltip, useMediaQuery, useTheme } from "@mui/material";
import { toast } from "react-toastify";
import { WorkBook} from "xlsx";
import { FixedSizeList as List } from "react-window";
import AutoSizer from "react-virtualized-auto-sizer";

import { Button, DatePicker, Input, SelectAutoComplete } from "@components";
import { Login } from "@components/modals";
import {
  documentTypeList,
  documentTypeListAIG,
  documentTypeListAXA,
  documentTypeListCASER,
  GENDER,
  PRODUCT_PROVIDERS,
  userCountriesListSpanish,
} from "@helpers/data";
import { useDebounce } from "@hooks";
import { checkUserExists, getMunicipals } from "@store/user/UserActions";
import { parseXlsxDate } from "@helpers/utils";
import TravellerForm from "./TravellerForm";
import PhoneInputComponent from "@components/PhoneInput";
import { Controller } from "react-hook-form";
import { useDropzone } from "react-dropzone";
import * as XLSX from "xlsx";

interface CheckoutFormProps {
  errors: any;
  control: any;
  isInsuredPolicyHolder: boolean;
  totalTravellers: number;
  productProvider: string;
  setValue: any;
  watch: any;
  getValues: any;
  citiesList: any[];
  setCitiesList: any;
  isCollaborator: boolean;
  variantAllowBulkXlsxUpload: boolean;
  validateTravellers: any;
  onValidated?: any;
}

const validXlsxList = [
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  "application/vnd.ms-excel",
  "application/vnd.ms-excel.sheet.macroEnabled.12",
  "application/vnd.ms-excel.template.macroEnabled.12",
  "application/vnd.ms-excel.addin.macroEnabled.12",
  "application/vnd.ms-excel.sheet.binary.macroEnabled.12",
  "application/vnd.oasis.opendocument.spreadsheet",
  "text/csv",
  "application/csv",
  "text/tab-separated-values",
];

const defaultTraveler = {
  name: "",
  surname: "",
  dob: new Date(),
  documentType: "DNI",
  documentNumber: "",
  gender: "",
};
const RenderTravellerFormItem = (({ index, style }: any) => (
  <div style={style}>
    <Paper
      elevation={2}
      key={index}
      className="mb-2 rounded-lg"
    >
      <TravellerForm
        index={index}
      />
    </Paper>
  </div>
));
const CheckoutForm: React.FC<CheckoutFormProps> = (props) => {
  const {
    errors,
    control,
    isInsuredPolicyHolder,
    totalTravellers,
    productProvider,
    setValue,
    watch,
    getValues,
    citiesList,
    setCitiesList,
    isCollaborator,
    variantAllowBulkXlsxUpload,
    validateTravellers,
    onValidated
  } = props;

  const documentTypeListFinal =
    productProvider === PRODUCT_PROVIDERS.CASER
      ? documentTypeListCASER
      : productProvider === PRODUCT_PROVIDERS.AIG
        ? documentTypeListAIG
        : productProvider === PRODUCT_PROVIDERS.AXA
          ? documentTypeListAXA :
          documentTypeList;

  // const { fields, append, remove } = useFieldArray({
  //   name: "travellers",
  //   control,
  // });

  const dispatch = useDispatch();

  const { municipals }: any = useSelector<any>(
    ({ Travelfine }) => Travelfine.user
  );

  const [openLoginModal, setOpenLoginModal] = useState(false);

  const [email, setEmail] = useState("");

  const isLoggedIn = localStorage.getItem("tr_access_token");

  // useEffect(() => {
  //   const travellers: any = isInsuredPolicyHolder
  //     ? totalTravellers - 1
  //     : totalTravellers;
  //   const newVal = travellers;
  //   const oldVal = fields.length;
  //   if (newVal > oldVal) {
  //     for (let i: any = oldVal; i < newVal; i++) {
  //       append({
  //         name: "",
  //         surname: "",
  //         dob: new Date(),
  //         documentType: "DNI",
  //         documentNumber: "",
  //       });
  //     }
  //   } else {
  //     for (let i: any = oldVal; i > newVal; i--) {
  //       remove(i - 1);
  //     }
  //   }
  // }, [totalTravellers, isInsuredPolicyHolder, fields.length, append, remove]);

  useEffect(() => {
    const travellersCount: any = isInsuredPolicyHolder
      ? totalTravellers - 1
      : totalTravellers;
    const newVal = travellersCount;

    const travellersList = getValues("travellers");

    const oldVal = travellersList.length;

    if (newVal > oldVal) {
      for (let i: any = oldVal; i < newVal; i++) {
        travellersList.push(defaultTraveler);
      }
    } else {
      for (let i: any = oldVal; i > newVal; i--) {
        travellersList.pop();
      }
    }

    setValue("travellers", [...JSON.parse(JSON.stringify(travellersList))]);
  }, [totalTravellers, isInsuredPolicyHolder, getValues, setValue]);

  const travellers = watch("travellers");
  const country = watch("country");

  const postalCodeChangeHandler = (event: any, field: any) => {
    if (productProvider === PRODUCT_PROVIDERS.IRIS && country === "España") {
      if (event?.target?.value.length <= 5) {
        field.onChange(event.target.value);
        setValue("city", "");
        setValue("province", "");
        setCitiesList([]);

        if (event?.target?.value?.length === 5) {
          dispatch(getMunicipals(event?.target?.value));
        }
      }
    } else {
      field.onChange(event.target.value);
    }
  };

  const debouncedEmail: string = useDebounce<string>(email, 2000);

  const openModal = () => setOpenLoginModal(true);

  const closeModal = () => setOpenLoginModal(false);

  const [searchParams] = useSearchParams();

  const userTypeParams = searchParams.get("userType");

  const emailChangeHandler = (event: any, field: any) => {
    /* eslint-disable no-useless-escape */
    /* eslint-disable no-control-regex */
    let rEmail =
      /^((([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*)|((\x22)((((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(([\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(\\([\x01-\x09\x0b\x0c\x0d-\x7f]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))))*(((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(\x22)))@((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))$/i; // eslint-disable-next-line
    if (
      event?.target?.value.match(rEmail) &&
      userTypeParams !== "collaborator"
    ) {
      setEmail(event?.target?.value);
    }
    field.onChange(event.target.value);
  };

  useEffect(() => {
    if (debouncedEmail && !isLoggedIn) {
      dispatch(checkUserExists({ email: debouncedEmail, openModal }));
    }
  }, [dispatch, isLoggedIn, debouncedEmail]);

  useEffect(() => {
    if (municipals && municipals.length > 0) {
      const cities = municipals.map((item: any) => {
        return { label: item.Des_Municipio, value: item.Id_Municipio };
      });

      setCitiesList(cities);

      setValue("city", {
        label: municipals[0].Des_Municipio,
        value: municipals[0].Id_Municipio,
      });

      setValue("province", {
        label: municipals[0].Des_Provincia,
        value: municipals[0].Id_Provincia,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [municipals, setValue]);

  const handleExampleXlsxDownload = async () => {
    const link = document.createElement("a");
    link.href =
      "https://travelfine.s3.eu-west-3.amazonaws.com/assets/example-xlsx/Plantilla_Asegurados.xlsx";
    link.setAttribute("download", `Plantilla_Asegurados.xlsx`);
    document.body.appendChild(link);
    link.click();
  };

  const normalizeString = (str: string) =>
    str
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "")
      .replace(/[^\w\s()]/g, "")
      .toLowerCase()
      .trim();

  const processRawTravellersData = async (
    rawTravellersData: { [key: string]: string }[]
  ) => {
    try {
      let travellersList: any = [];

      for (let index = 0; index < rawTravellersData.length; index++) {
        const item = rawTravellersData[index];

        const normalizedKeys = Object.keys(item).reduce((acc: any, key) => {
          acc[normalizeString(key)] = item[key];
          return acc;
        }, {});


        const name = normalizedKeys["nombre"];
        const surname = normalizedKeys["apellidos"] ? normalizedKeys["apellidos"] : normalizedKeys["primer apellido"];
        const secondSurname = normalizedKeys["segundo apellido"];
        let gender = normalizedKeys["genero"] ? normalizedKeys["genero"] : GENDER[1].value;
        const dob = (normalizedKeys["fecha de nacimiento"] &&  normalizedKeys["fecha de nacimiento"] !== "dd/mm/aaaa" )? normalizedKeys["fecha de nacimiento"] : null;
        const documentType = normalizedKeys["tipo de documento"] ? normalizedKeys["tipo de documento"].toUpperCase() : '';
        const documentNumber = normalizedKeys["documento de indentidad"];

        if (gender) {
          gender = GENDER?.find((genero) => genero?.value?.includes(gender))?.value ?? 'Hombre';
        }

        let parsedDate = new Date(new Date().getDate() - 1);
        if (dob) {
          if (typeof dob !== "number" && dob) {
            toast.error(`Fecha no válida en la fila ${index + 1}`);
            return;
          }
          parsedDate = parseXlsxDate(dob);
        }
        
        // if (!isValid(parsedDate)) {
        //   toast.error(`Fecha no válida en la fila ${index + 1}`);
        //   return;
        //   // parsedDate = defaultTraveler.dob;
        // }

        travellersList.push({
          name,
          surname: (PRODUCT_PROVIDERS.CASER !== productProvider || PRODUCT_PROVIDERS.AXA !== productProvider) ? `${surname} ${secondSurname}` : surname,
          secondSurname: (PRODUCT_PROVIDERS.CASER !== productProvider || PRODUCT_PROVIDERS.AXA !== productProvider) ? '' : secondSurname,
          dob: parsedDate,
          documentType: documentType,
          documentNumber,
          gender
        });
      }

      let errorIndex: string | number = "unknown";
      try {
        await Promise.all(
          travellersList.map(async (object: any, index: number) => {
            errorIndex = index;
            const nIndex = travellersList.length > travellers.length ? travellersList.length - travellers.length : travellers.length - travellersList.length;
            if (index <= nIndex)
              await validateTravellers.validate(object, { abortEarly: false });
          })
        );
      } catch (err: any) {
        const validationError =
          `${err?.errors?.[0]} en la fila ${errorIndex + 1}` ??
          "El archivo no tiene el formato correcto";
        toast.error(validationError);
        return;
      }

      if (travellersList.length < travellers.length) {
        const missingTravellersCount = travellers.length - travellersList.length;
        for (let i = 1; i <= missingTravellersCount; i++) {
          travellersList.push(defaultTraveler);
        }
      }

      // if (travellersList.length > travellers.length) {
      //   toast.error(
      //     "El número de viajeros del fichero no coincide con el número de viajeros seleccionado"
      //   );
      //   return;
      // }

      if (travellersList.length > travellers.length) {
        const n = travellersList.length - travellers.length;
        const rem = (a: any, n: any) => {
          return a.filter((_: any, index: any) => index < a.length - n);
        };
        travellersList = rem(travellersList, n);

      }

      setValue("travellers", [...travellersList]);
    } catch (err: any) {
      console.log("err", err);
      toast.error("El archivo no tiene el formato correcto");
    }
  };

  const handleXlsxUpload = async (input: React.ChangeEvent<HTMLInputElement> | File) => {
    let file: File | undefined;

    if (input instanceof File) {
      file = input;
    } else if (input.target.files?.length) {
      file = input.target.files[0];
    }

    if (!file) return;

    if (!validXlsxList.includes(file.type)) {
      toast.error("El archivo no es un fichero Excel");
      return;
    }

    const reader = new FileReader();

    reader.onload = function (e) {
      if (!e.target) return;
      const data = e.target.result;

      const workbook = XLSX.read(data, { type: "binary" });

      sanitizeExcelDataOfCorruptedCells(workbook);

      const rawTravellersData: { [key: string]: string }[] =
        XLSX.utils.sheet_to_json(workbook.Sheets[workbook.SheetNames[0]]);

      processRawTravellersData(rawTravellersData);
    };

    reader.readAsBinaryString(file);
  };

  const handleDrop = async (acceptedFiles: File[]) => {
    if (acceptedFiles.length === 0) return;
    const file = acceptedFiles[0];
    await handleXlsxUpload(file);
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: handleDrop,
    accept: validXlsxList.join(","),
    multiple: false,
  });
  const theme = useTheme();
  const isLargeDevice = useMediaQuery(theme.breakpoints.up("md"));
  const isShortDevice = useMediaQuery(theme.breakpoints.up("sm"));

  const ITEM_HEIGHT = isLargeDevice ? (productProvider === PRODUCT_PROVIDERS.AXA ? 320 : 250) : (isShortDevice ? 300 : (productProvider === PRODUCT_PROVIDERS.AXA ? 600 : 450));
  const DEFAULT_CONTAINER_HEIGHT = 500;
  const travellersListContainerHeight =
    travellers.length <= 20
      ? ITEM_HEIGHT * travellers.length
      : DEFAULT_CONTAINER_HEIGHT;

  const itemHeight = travellers.length <= 20 ? ITEM_HEIGHT : ITEM_HEIGHT + 20;

  return (
    <>
      <div className="flex flex-col">
        <p className="self-center mt-2 text-xl font-bold lg:self-stretch lg:mt-0">
          Datos del tomador
        </p>
        <Paper
          elevation={3}
          className="flex flex-col px-4 py-4 mt-2 rounded-lg"
        >
          <div className="flex flex-col items-start justify-between mt-2 mb-3 space-y-3 lg:flex-row lg:space-x-3 lg:space-y-0">
            <Input
              label="Nombre"
              name="name"
              control={control}
              fullWidth
              error={!!errors.name}
              errorMessage={errors?.name?.message}
              className="w-full"
              size="small"
              required
              labelOutside
            />
            <Input
              label={productProvider === PRODUCT_PROVIDERS.CASER || productProvider === PRODUCT_PROVIDERS.AXA ? 'Primer apellido' : 'Apellidos'}
              name="surname"
              control={control}
              fullWidth
              error={!!errors.surname}
              errorMessage={errors?.surname?.message}
              className="w-full"
              size="small"
              required
              labelOutside
            />
            {(productProvider === PRODUCT_PROVIDERS.CASER || productProvider === PRODUCT_PROVIDERS.AXA) && <Input
              label="Segundo Apellido"
              name="secondSurname"
              control={control}
              fullWidth
              error={!!errors.secondSurname}
              errorMessage={errors?.surname?.secondSurname}
              className="w-full"
              size="small"
              required
              labelOutside
            />}
            <DatePicker
              name="dob"
              label="Fecha Nacimiento"
              control={control}
              fullWidth={true}
              error={!!errors.dob}
              errorMessage={errors?.dob?.message}
              className="w-full !normal-case"
              size="small"
              maxDate={new Date()}
              required
              labelOutside
              disableTextField
            />
            <SelectAutoComplete
              label="Tipo documento"
              name="documentType"
              control={control}
              fullWidth
              error={!!errors.documentType}
              errorMessage={errors?.documentType?.message}
              className="w-full"
              list={documentTypeListFinal}
              size="small"
              disableClearable
              required
              labelOutside
              onChange={(newValue: any, field: any) => {
                field.onChange(newValue.value);
                setValue("documentNumber", "");
              }}
            />
            <Input
              label="Nº documento"
              name="documentNumber"
              control={control}
              fullWidth
              error={!!errors.documentNumber}
              errorMessage={errors?.documentNumber?.message}
              className="w-full"
              size="small"
              required
              labelOutside
              onChange={(event: any, field: any) => {
                const value = event.target.value.replace(/\s+/g, "");
                field.onChange(value);
              }}
            />
          </div>
          <div className="flex flex-col items-start justify-between mt-2 mb-3 space-y-3 lg:flex-row lg:space-x-3 lg:space-y-0">
            {productProvider === PRODUCT_PROVIDERS.AXA && (<SelectAutoComplete
              label="Género"
              name="gender"
              control={control}
              fullWidth
              error={!!errors.gender}
              errorMessage={errors?.gender?.message}
              className="w-full"
              list={GENDER}
              size="small"
              disableClearable
              required
              labelOutside
              onChange={(newValue: any, field: any) => {
                field.onChange(newValue.value);
              }}
            />)}
            <SelectAutoComplete
              label="Pais"
              name="country"
              control={control}
              fullWidth
              error={!!errors.country}
              errorMessage={errors?.country?.message}
              className="w-full"
              list={userCountriesListSpanish}
              size="small"
              disableClearable
              required
              labelOutside
              onChange={(newValue: any, field: any) => {
                field.onChange(newValue.value);
                setCitiesList([]);
                setValue("postalCode", "");
                setValue("province", "");
                setValue("city", "");
                setValue("address", "");
              }}
            />
            <Input
              label="Codigo Postal"
              name="postalCode"
              control={control}
              fullWidth
              onChange={postalCodeChangeHandler}
              error={!!errors.postalCode}
              errorMessage={errors?.postalCode?.message}
              className="w-full numberInputWithoutArrows"
              size="small"
              required
              labelOutside
            />
            <Input
              label="Provincia"
              name="province"
              control={control}
              fullWidth
              error={!!errors.province}
              errorMessage={errors?.province?.message}
              className="w-full"
              size="small"
              required
              labelOutside
              disabled={
                productProvider === PRODUCT_PROVIDERS.IRIS &&
                country === "España"
              }
            />
            {citiesList.length > 1 ? (
              <SelectAutoComplete
                label="Ciudad"
                name="city"
                control={control}
                fullWidth
                error={!!errors.city}
                errorMessage={errors?.city?.message}
                className="w-full"
                list={citiesList}
                size="small"
                disableClearable
                required
                labelOutside
                saveObject
              />
            ) : (
              <Input
                label="Ciudad"
                name="city"
                control={control}
                fullWidth
                error={!!errors.city}
                errorMessage={errors?.city?.message}
                className="w-full"
                size="small"
                required
                labelOutside
                disabled={
                  productProvider === PRODUCT_PROVIDERS.IRIS &&
                  country === "España"
                }
              />
            )}
          </div>
          <div className="flex flex-col items-start justify-between mt-2 mb-3 space-y-3 lg:flex-row lg:space-x-3 lg:space-y-0">
            <Input
              label="Dirección"
              name="address"
              control={control}
              fullWidth
              error={!!errors.address}
              errorMessage={errors?.address?.message}
              className="w-full"
              size="small"
              required
              labelOutside
            />
            <Input
              label="Email"
              name="email"
              control={control}
              onChange={emailChangeHandler}
              fullWidth
              error={!!errors.email}
              errorMessage={errors?.email?.message}
              className="w-full"
              size="small"
              required
              labelOutside
            />
            <div className={`flex w-full justify-center`}>
              <Controller
                name={"phoneNo"}
                control={control}
                render={({ field }) => (
                  <PhoneInputComponent
                    label="Telefono"
                    control={control}
                    country={country}
                    required
                    error={!!errors.phoneNo}
                    errorMessage={errors.phoneNo?.message}
                    setValue={setValue}
                    productProvider={productProvider}
                    onValidated={onValidated}
                    {...field}
                  />
                )}
              />
            </div>
          </div>
          {/* <p className="mt-5 font-medium">
            Marque la casilla “Estas en destino” si el asegurado se encuentra en
            el destino al contratar la póliza.
          </p> */}
          <div className="flex flex-col lg:flex-row">
            <div className="flex flex-col">
              <Input
                label={
                  <p className="flex items-center">
                    El tomador es el viajero principal
                    <Tooltip
                      placement="top"
                      title="Tomador del Seguro La persona que celebra el contrato  y que es responsable del pago de la prima."
                    >
                      <span>
                        <HelpOutlineIcon className="ml-1 text-gray-600" />
                      </span>
                    </Tooltip>
                  </p>
                }
                name="isInsuredPolicyHolder"
                control={control}
                fullWidth
                error={!!errors.isInsuredPolicyHolder}
                errorMessage={errors?.isInsuredPolicyHolder?.message}
                className="mb-2"
                size="small"
                type="checkbox"
                color="secondary"
              />
              {/* <Input
                label="Usar la misma dirección para todos"
                name="sameAddress"
                control={control}
                fullWidth
                error={!!errors.sameAddress}
                errorMessage={errors?.sameAddress?.message}
                className="mb-2"
                size="small"
                type="checkbox"
                color="secondary"
              /> */}
              {/* <Input
                label="Asegurado en destino"
                name="destinationInsured"
                control={control}
                fullWidth
                error={!!errors.destinationInsured}
                errorMessage={errors?.destinationInsured?.message}
                className="mb-2"
                size="small"
                type="checkbox"
                color="secondary"
              /> */}
            </div>
          </div>
        </Paper>
        {travellers.length > 0 && (
          <div className="mt-8">
            {/* {fields.length > 0 && <p className="text-xl font-bold">Viajeros</p>}
          {fields.map((item, i) => (
            <Paper
              elevation={2}
              key={item.id}
              className="px-4 py-4 mt-6 rounded-lg"
            >
              <TravellerForm index={i} control={control} errors={errors} />
            </Paper>
          ))} */}
            {isCollaborator && variantAllowBulkXlsxUpload && (
              <div className="flex items-center justify-between">
                <p className="text-xl font-bold">Viajeros</p>
                <div className="flex justify-end gap-4 py-4">
                  <Button onClick={handleExampleXlsxDownload}>Descargar Plantilla</Button>
                  <div {...getRootProps()} className="relative">
                    <input {...getInputProps()} className="hidden" />
                    <Button color="secondary" className={`w-full ${isDragActive ? "bg-green-500" : ""}`}>
                      <label className="cursor-pointer" htmlFor="uploadTravellerXlsx">
                        {isDragActive ? "¡Suelta el archivo aquí!" : "Subir Fichero"}
                      </label>
                    </Button>
                  </div>
                </div>
              </div>
            )}
            {/* {travellers.map((item: any, i: any) => (
              <Paper
                elevation={2}
                key={i}
                className="px-4 py-4 mt-4 rounded-lg"
              >
                <TravellerForm
                  index={i}
                  control={control}
                  errors={errors}
                  setValue={setValue}
                />
              </Paper>
            ))} */}
            <div
              style={{ height: travellersListContainerHeight }}
              className="w-full my-4 overflow-y-auto"
            >
              <AutoSizer>
                {({ height, width }: any) => (
                  <List
                    className="overflow-y-auto List"
                    height={height}
                    itemCount={travellers.length}
                    itemSize={itemHeight}
                    // width={"100%"}
                    width={width - travellers.length}
                  >{RenderTravellerFormItem}
                  </List>
                )}
              </AutoSizer>
            </div>
            {/* <Virtuoso
              style={{
                height: travellersListContainerHeight,
              }}
              className="py-6"
              totalCount={clonedTravellers.length}
              data={JSON.parse(JSON.stringify(clonedTravellers))}
              itemContent={(i: number, traveller: any) => (
                <Paper
                  elevation={2}
                  key={i}
                  className="px-4 py-4 my-4 rounded-lg"
                >
                  <TravellerForm
                    index={i}
                    control={control}
                    errors={errors}
                    setValue={setValue}
                  />
                </Paper>
              )}
            /> */}
          </div>
        )}
      </div>
      <Login
        open={openLoginModal}
        email={debouncedEmail}
        onClose={closeModal}
      />
    </>
  );
};

export default CheckoutForm;

// EXCEL HELPER
function sanitizeExcelDataOfCorruptedCells(readedData: WorkBook) {
  const sheet = readedData.Sheets.Hoja1;
  for (let key in sheet) {
    if (key !== "E1" && key.startsWith("E")) {
      const obj = sheet[key];

      if (obj.t === "s") {
        obj.t = "n";
        obj.v = convertCorruptedDateToExcelDate(obj.v);
        delete obj.h;
        delete obj.r;
      }
    }
  }
}

function convertCorruptedDateToExcelDate(dateString: string) {
  const parts = dateString.split("/");
  var date = new Date(
    parseInt(parts[2]),
    parseInt(parts[1]) - 1,
    parseInt(parts[0])
  );

  let converted =
    25569.0 +
    (date.getTime() - date.getTimezoneOffset() * 60 * 1000) /
    (1000 * 60 * 60 * 24);

  return converted;
}
