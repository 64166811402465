// import { useForm } from "react-hook-form";
// import { yupResolver } from "@hookform/resolvers/yup";
// import * as yup from "yup";
import { DatePicker, Input, SelectAutoComplete } from "@components";
import { documentTypeList, documentTypeListAXA, documentTypeListCASER, GENDER, PRODUCT_PROVIDERS } from "@helpers/data";
import { useFormContext, useWatch } from "react-hook-form";
import { useLocation } from "react-router-dom";

interface TravellerFormProps {
  index: any;
  productProvider?: any;
  // errors: any;
  // control: any;
  // setValue: any;
}

interface ProductState {
  type: string;
  price: number;
  filter: {
    modality: {
      label: string;
      value: string;
      key: string;
      isHidden: boolean;
    };
    origin: {
      label: string;
      value: string;
      region: string;
      code: string;
      groupTitle: string;
    };
    destination: {
      label: string;
      value: string;
      code: string;
      groupTitle: string;
    };
    departureDate: string;
    returnDate: string;
    totalTravellers: number;
    codeKey: string;
    userInDestination: boolean;
    pricing: boolean;
    dontCallAPI: boolean;
  };
  productId: string;
  productOverCosts: {
    _id: string;
    id: string;
    title: string;
    overcost: number;
    key: string;
    limit: string;
    overcostAdded: number;
    overcostType: string;
    axaId?: number;
  }[];
  productProvider: string;
  coveragePdfFile: string;
  emailPdfFiles: string[];
  budgetId: string | null;
  irisExtraSelected: string | null;
  variantAllowBulkXlsxUpload: boolean;
}

// const defaultValues = {
//   name: "",
//   surname: "",
//   dob: new Date(),
//   documentType: "DNI",
//   documentNumber: 1,
// };

const TravellerForm: React.FC<TravellerFormProps> = (props) => {
  const { state }: { state: ProductState } = useLocation();
  const {
    productProvider
  }: ProductState = state;

  const { index } = props;
  const { control, setValue, formState } = useFormContext();

  const { errors } = formState;
  //   const schema = yup.object().shape({
  //     name: yup.string().required("Nombre es obligatorio"),
  //     surname: yup.string().required("Apellidos obligatorio"),
  //     dob: yup.date().required("Fecha Nacimiento obligatorio"),
  //     documentType: yup.string().required("Tipo documento es obligatorio"),
  //     documentNumber: yup.string().required("DNI o pasaporte es obligatorio"),
  //   });
  //   const methods = useForm({
  //     mode: "onBlur",
  //     defaultValues,
  //     resolver: yupResolver(schema),
  //   });
  //   const { control, handleSubmit, formState } = methods;
  //   const { errors, isValid } = formState;

  const name = useWatch({
    control,
    name: `travellers.${index}.name`,
  });
  const surname = useWatch({
    control,
    name: `travellers.${index}.surname`,
  });
  const secondSurname = useWatch({
    control,
    name: `travellers.${index}.secondSurname`,
  });
  const dob = useWatch({
    control,
    name: `travellers.${index}.dob`,
  });
  const documentType = useWatch({
    control,
    name: `travellers.${index}.documentType`,
  });
  const documentNumber = useWatch({
    control,
    name: `travellers.${index}.documentNumber`,
  });
  const gender = useWatch({
    control,
    name: `travellers.${index}.gender`,
  });

  const documentTypeListFinal =
    productProvider === PRODUCT_PROVIDERS.CASER
      ? documentTypeListCASER :
      productProvider === PRODUCT_PROVIDERS.AXA
        ? documentTypeListAXA : documentTypeList;

  return (
    <div className="flex flex-col p-4 mb-2">
      <p className="mb-2 font-bold">Viajero {index + 1}</p>
      <div className="flex flex-wrap items-start pace-y-3 lg:space-y-0">
        <div className="w-full p-2 sm:w-1/2 md:w-1/3">
          <Input
            label="Nombre"
            name={`travellers.${index}.name`}
            control={control}
            fullWidth
            error={!!errors?.travellers?.[index]?.name}
            errorMessage={errors?.travellers?.[index]?.name?.message}
            className=""
            size="small"
            required
            value={name}
            labelOutside
          />
        </div>
        <div className="w-full p-2 sm:w-1/2 md:w-1/3">
          <Input
            label={(productProvider === PRODUCT_PROVIDERS.CASER || productProvider === PRODUCT_PROVIDERS.AXA) ? 'Primer apellido' : 'Apellidos'}
            name={`travellers.${index}.surname`}
            control={control}
            fullWidth
            error={!!errors?.travellers?.[index]?.surname}
            errorMessage={errors?.travellers?.[index]?.surname?.message}
            className="w-full"
            size="small"
            required
            value={surname}
            labelOutside
          />
        </div>
        {(productProvider === PRODUCT_PROVIDERS.CASER || productProvider === PRODUCT_PROVIDERS.AXA) && (
          <div className="w-full p-2 sm:w-1/2 md:w-1/3">
            <Input
              label="Segundo apellido"
              name={`travellers.${index}.secondSurname`}
              control={control}
              fullWidth
              error={!!errors?.travellers?.[index]?.secondSurname}
              errorMessage={errors?.travellers?.[index]?.secondSurname?.message}
              className="w-full"
              size="small"
              value={secondSurname}
              required
              labelOutside
            />
          </div>
        )}
        {productProvider === PRODUCT_PROVIDERS.AXA && (<div className="w-full p-2 sm:w-1/2 md:w-1/3">
          <SelectAutoComplete
            label="Género"
            name={`travellers.${index}.gender`}
            control={control}
            fullWidth
            error={!!errors?.travellers?.[index]?.gender}
            errorMessage={errors?.travellers?.[index]?.gender?.message}
            className="w-full"
            list={GENDER}
            size="small"
            value={gender}
            disableClearable
            required={productProvider === PRODUCT_PROVIDERS.AXA}
            labelOutside
            onChange={(newValue: any, field: any) => {
              field.onChange(newValue.value);
            }} />
        </div>)}
        <div className="w-full p-2 sm:w-1/2 md:w-1/3">
          <DatePicker
            label="Fecha Nacimiento"
            name={`travellers.${index}.dob`}
            control={control}
            fullWidth
            error={!!errors?.travellers?.[index]?.dob}
            errorMessage={errors?.travellers?.[index]?.dob?.message}
            className="w-full"
            size="small"
            required
            value={dob}
            labelOutside
            disableTextField
          />
        </div>
        <div className="w-full p-2 sm:w-1/2 md:w-1/3">
          <SelectAutoComplete
            label="Tipo documento"
            name={`travellers.${index}.documentType`}
            control={control}
            fullWidth
            error={!!errors?.travellers?.[index]?.documentType}
            errorMessage={errors?.travellers?.[index]?.documentType?.message}
            className="w-full"
            list={documentTypeListFinal}
            size="small"
            disableClearable
            required
            labelOutside
            value={documentType}
            onChange={(newValue: any, field: any) => {
              field.onChange(newValue.value);
              setValue(`travellers.${index}.documentNumber`, "");
            }}
          />
        </div>
        <div className="w-full p-2 sm:w-1/2 md:w-1/3">
          <Input
            label="Nº documento"
            name={`travellers.${index}.documentNumber`}
            control={control}
            fullWidth
            error={!!errors?.travellers?.[index]?.documentNumber}
            errorMessage={errors?.travellers?.[index]?.documentNumber?.message}
            className="w-full"
            size="small"
            required
            labelOutside
            value={documentNumber}
          />
        </div>
      </div>
    </div>
  );
};

export default TravellerForm;
