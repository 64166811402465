import { BrandingHeader, ExternalLink, Footer, Loader } from "@components";
import { useTheme } from "@mui/styles";
import { Box } from "@mui/system";
import { getCategories } from "@store/category/CategoryActions";
import { autoLogin } from "@store/user/UserActions";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import Cookies from "js-cookie";

import ContactInfo from "./components/ContactInfo";
import Details from "./components/Details";
import Filter from "./components/Filter";
import MobileAppSection from "./components/MobileAppSection";
import PlanTable from "./components/PlanTable/PlanTable";
import "react-quill/dist/quill.snow.css";
import { COLLABORATOR_PANEL_URL_PARAMETER_NAME, MID_CATEGOIRES, timezoneToCountryMap } from "@helpers/data";
import { CODE_RASTREATOR, GLOBAL_FINE, COOKIE_EXPIRE, CODE_GLOBAL_FINE_HOME_PRO, CODE_GLOBAL_FINE_PRO, CODE_HOME_MX, CODE_TRAVEL_FINE_HOME_PRO } from "@constants/constants";
import { getCodeByKey, resetCode } from "@store/code/CodeActions";

interface PurchasePoliciesProps { }

const defaultValues = {
  modality: {
    label: "",
    value: "",
  },
  origin: {
    label: "España y Andorra",
    value: "España y Andorra",
    code: "ES",
    region: "europa",
  },
  destination: { label: "Todo el mundo", value: "Todo el mundo", code: "WR" },
  departureDate: new Date(),
  returnDate: new Date(),
  totalTravellers: 1,
  userInDestination: false,
};

const PurchasePolicies: React.FC<PurchasePoliciesProps> = (props) => {
  const { filter }: any = useSelector<any>(
    ({ Travelfine }) => Travelfine.product
  );
  const [countryCode, setCountryCode] = useState<any>(null);
  const theme: any = useTheme();

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  let code = searchParams.get("code");
  const applyCollaboratorPanelStyles =
    searchParams.get(COLLABORATOR_PANEL_URL_PARAMETER_NAME) === "true";

  const loginToken = searchParams.get("loginToken");
  const { brandColor, code: codeSearch }: any = useSelector<any>(
    ({ Travelfine }) => Travelfine.code
  );

  useEffect(() => {
    dispatch(getCategories());
  }, [dispatch]);

  useEffect(() => {
    if (loginToken) {
      dispatch(autoLogin({ loginToken }));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const doRender = (code && brandColor) || !code;

  const modality = filter?.modality ?? null;
  const isModalityMexico = modality ? ((MID_CATEGOIRES.includes(modality?.key) || modality?.key === 'MultiviajeAnual') ? true : false) : false;

  // if(!doRender){
  //   return <Loader loading/>;
  // }

  return (
    <>
        <div className="flex flex-col bg-gray-50">
          <BrandingHeader />
          <div className="top-0 z-20 lg:sticky" style={{
            color: theme.palette.secondary.contrastText
          }}>
            <Filter
              applyCollaboratorPanelStyles={applyCollaboratorPanelStyles}
              filter={filter}
              defaultValues={defaultValues}
              code={codeSearch}
            />
          </div>
          <div className="lg:block">
            <Details
              applyCollaboratorPanelStyles={applyCollaboratorPanelStyles}
            />
          </div>
          <div className="flex flex-col items-center px-4 mt-3 lg:px-20 lg:my-8">
            <h1 className="text-2xl font-bold text-center font-calistoga lg:text-4xl">
              {!isModalityMexico ? 'Creamos el seguro perfecto' : 'Creamos la asistencia'}
              <span
                style={{
                  color: theme.palette.secondary.main,
                }}
              >
                {" "}
                {!isModalityMexico ? 'a la medida de tu viaje' : 'en viaje perfecta a tu escapada'}
              </span>
            </h1>
            <h1 className="lg:text-2xl text-lg mt-3 text-center px-1.5 lg:px-8">
              Tu tranquilidad 100% asegurada frente al
              <ExternalLink
                disabled={applyCollaboratorPanelStyles}
                href="https://travelfine.es/seguro-de-viaje-covid-19/"
              >
                {" "}
                <Box
                  className="transition duration-300 ease-in-out"
                  sx={[
                    {
                      "&:hover": {
                        color: "secondary.main",
                      },
                      display: "inline-block",
                    },
                  ]}
                >
                  <span className="font-medium underline">Covid-19*</span>
                </Box>
              </ExternalLink>{" "}
              y sus consecuencias.
            </h1>
          </div>
          <PlanTable filter={filter} />
          <div className="px-4 my-6 lg:px-20 lg:my-10">
            <MobileAppSection
              applyCollaboratorPanelStyles={applyCollaboratorPanelStyles}
            />
          </div>
          <p className="px-4 mt-6 text-2xl font-medium text-center font-calistoga lg:text-4xl lg:px-20 lg:mt-12">
            Tus dudas,{" "}
            <span
              style={{
                color: theme.palette.secondary.main,
              }}
            >
              te las resolvemos
            </span>
          </p>
          <p className="px-4 mt-4 text-lg text-center lg:text-2xl lg:px-40">
            Elige el canal que mejor se adapte a ti y uno de nuestros
            profesionales resolverá tus dudas de inmediato.
          </p>
          <div className="flex justify-center w-full px-2 my-4 lg:my-10 item-center">
            <ContactInfo
              applyCollaboratorPanelStyles={applyCollaboratorPanelStyles}
            />
          </div>

          <Footer />
        </div>
    </>
  );
};

export default PurchasePolicies;
