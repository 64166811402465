import React from "react";
import TextField from "@mui/material/TextField";
import { Controller } from "react-hook-form";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  MenuItem,
} from "@mui/material";
import { makeStyles } from "@mui/styles";

interface Props {
  required?: boolean;
  onClick?: any;
  type?: string;
  className?: string;
  style?: object;
  name: string;
  label?: any;
  errorMessage?: string;
  control: any;
  error?: boolean;
  fullWidth?: boolean;
  multiline?: boolean;
  rows?: number;
  color?:
    | "primary"
    | "secondary"
    | "success"
    | "error"
    | "info"
    | "warning"
    | "default"
    | undefined;
  size?: "small" | "medium" | undefined;
  list?: any;
  variant?: "outlined" | "standard" | "filled" | undefined;
  placeholder?: string;
  labelOutside?: boolean;
  onChange?: any;
  disabled?: boolean;
  value?: any;
}

const useStyles: any = makeStyles((theme: any) => ({
  root: {
    "& .MuiFilledInput-root": {
      backgroundColor: "white !important",
    },
  },
}));

const Input: React.FC<Props> = (props) => {
  const classes = useStyles();

  const {
    name,
    label,
    control,
    type,
    error,
    errorMessage,
    required,
    fullWidth,
    className,
    multiline,
    rows,
    variant,
    list,
    size,
    color,
    placeholder,
    labelOutside,
    onChange,
    disabled,
    value,
  } = props;
  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) =>
        type === "select" ? (
          <div className="flex flex-col w-full">
            {labelOutside && (
              <label className="text-sm font-medium whitespace-nowrap">
                {label}
                {required && "*"}
              </label>
            )}
            <TextField
              {...field}
              onChange={(event) => {
                onChange ? onChange(event, field) : field.onChange(event);
              }}
              disabled={disabled}
              className={`${classes.root} capitalize ${className}`}
              variant={variant ? variant : "outlined"}
              select
              label={!labelOutside && label}
              error={error}
              helperText={errorMessage}
              required={required}
              fullWidth={fullWidth}
              size={size}
            >
              {list.map((listItem: any) => {
                return (
                  <MenuItem key={listItem.value} value={listItem.value}>
                    {listItem.label}
                  </MenuItem>
                );
              })}
            </TextField>
          </div>
        ) : type === "checkbox" ? (
          <FormControl
            className={`${className}`}
            error={error}
            required={required}
          >
            <FormControlLabel
              label={label}
              control={
                <Checkbox
                  color={color}
                  {...field}
                  disabled={disabled}
                  onChange={(event) => {
                    onChange ? onChange(event, field) : field.onChange(event);
                  }}
                  checked={field.value}
                />
              }
            />
            <FormHelperText>{errorMessage}</FormHelperText>
          </FormControl>
        ) : (
          <div className="flex flex-col w-full">
            {labelOutside && (
              <label className="text-sm font-medium whitespace-nowrap">
                {label}
                {required && "*"}
              </label>
            )}
            <TextField
              {...field}
              onChange={(event) => {
                onChange ? onChange(event, field) : field.onChange(event);
              }}
              value={
                value
                  ? value
                  : field?.value?.label
                  ? field.value.label
                  : field.value
              }
              className={`${classes.root} ${className}`}
              variant={variant ? variant : "outlined"}
              label={!labelOutside && label}
              type={type}
              error={error}
              helperText={errorMessage}
              required={required}
              fullWidth={fullWidth}
              multiline={multiline}
              rows={rows}
              size={size}
              placeholder={placeholder}
              disabled={disabled}
            />
          </div>
        )
      }
    />
  );
};

export default Input;
