import React, { useState, useEffect } from "react";
import PhoneInput from "react-phone-input-2";
import es from "react-phone-input-2/lang/es.json";
import { getCountryCallingCode, parsePhoneNumberFromString } from "libphonenumber-js";
import { PRODUCT_PROVIDERS, userCountriesListSpanish } from "@helpers/data";
import "react-phone-input-2/lib/style.css";
import { makeStyles } from "@mui/styles";
import { FormHelperText } from "@mui/material";

interface PhoneInputProps {
    name: string;
    control: any;
    error?: boolean;
    errorMessage?: string;
    required?: boolean;
    fullWidth?: boolean;
    className?: string;
    label?: string;
    variant?: "outlined" | "standard" | "filled" | undefined;
    size?: "small" | "medium" | undefined;
    disabled?: boolean;
    value?: string;
    onChange?: any;
    country?: string;
    setValue?: any;
    productProvider?: any;
    onValidated?: any;
}
const useStyles: any = makeStyles((theme: any) => ({
  root: {
    "& .MuiFilledInput-root": {
      backgroundColor: "white !important",
    },
  },
}));

const PhoneInputComponent: React.FC<PhoneInputProps> = ({
    name,
    error,
    errorMessage,
    required,
    label,
    disabled,
    value = "",
    productProvider,
    onValidated,
    onChange
}) => {
    const [defaultCountry, setDefaultCountry] = useState("es");
    const [phone, setPhone] = useState(value ?? null);
    const classes = useStyles();
    const validatePhoneNumber = (inputValue: string, data: any) => {
        let isValid = false;
        let isCaserValid = true;
        const phoneNumber = parsePhoneNumberFromString(inputValue, data.iso2?.toUpperCase() ?? defaultCountry.toUpperCase())

        if (productProvider === PRODUCT_PROVIDERS.CASER) {
            const dataIso = data.iso2?.toUpperCase() ?? defaultCountry.toUpperCase();
            isCaserValid = dataIso === userCountriesListSpanish.find((dataCountry) => dataCountry.label.includes('España'))?.isoCode.toUpperCase();
        }

        if (phoneNumber) {
            isValid = phoneNumber.isValid();
        }
        onValidated(isValid && isCaserValid);
        return isValid && isCaserValid;
    };

    const handleOnChange = (value: any, data: any, onChange: any) => {
        setDefaultCountry(data.countryCode);
        setPhone(value);
        validatePhoneNumber(value, data);
        onChange(value.slice(data.dialCode.length))
    }

    const getDialCodeFromIsoCode = (isoCode: any): string | null => {
        try {
            const dialCode = getCountryCallingCode(isoCode);
            return dialCode;
        } catch (error) {
            return '34';
        }
    };

    const ensureDialCode = (inputValue: string, countryCode: any) => {
        const dialCode = getDialCodeFromIsoCode(countryCode.toUpperCase());
        if ((dialCode && inputValue.startsWith(dialCode)) || inputValue.length <= 0 || inputValue.length === 9) {
            return `${dialCode}${inputValue}`;
        }
        return inputValue;
    };

    useEffect(() => {
        if (value) {
            const validatedNumber = ensureDialCode(value, defaultCountry);
            setPhone(validatedNumber);
        }
    }, []);

    return (

        <div className={`${classes.root} flex flex-col w-full`}>
            {label && (
                <label className="text-sm font-medium whitespace-nowrap">
                    {label}
                    {required && "*"}
                </label>
            )}
            <PhoneInput
                value={phone ?? value}
                country={defaultCountry}
                localization={es}
                isValid={validatePhoneNumber}
                onChange={(value: any, data: any,) => handleOnChange(value, data, onChange)}
                inputProps={{
                    name,
                    required,
                    disabled: disabled,
                    autoFocus: false,
                }}
                inputStyle={{
                    borderRadius: "4px",
                    width: "100%",
                    height: "40px",
                }}
                enableSearch
            />
            {error && (
                <FormHelperText error>{errorMessage}</FormHelperText >
            )}
        </div>
    );
};

export default PhoneInputComponent;
