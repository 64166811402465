import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { useStripe, useElements } from "@stripe/react-stripe-js";
import { Paper } from "@mui/material";
import { CardNumberElement } from "@stripe/react-stripe-js";
import { Button } from "@components";
import { checkMinimunAge, getFormattedDate, numberWithCommas } from "@helpers/utils";
import { axiosInstance } from "@api/axios";
import { createClientSecretUrl } from "@api/Endpoint";
import { createPolicy } from "@store/policy/PolicyActions";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { getCodeByKey, getCollaboratorCodes } from "@store/code/CodeActions";
import { toast } from "react-toastify";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useTheme } from "@mui/styles";
import { CreatePassword } from "@components/modals";
import { format } from "date-fns";
import { MID_CATEGOIRES, PAYMENT_STATUS, PRODUCT_PROVIDERS } from "@helpers/data";
import { CODE_TRAVEL_FINE_HOME_PRO, TYPE_CODE } from "@constants/constants";

interface ContractSummaryProps {
  isValid: any;
  dirtyFields: any;
  filter: any;
  type: string;
  price: number;
  handleSubmit: any;
  productId?: any;
  setProcessingTo: any;
  setCheckoutError: any;
  checkoutError: any;
  isDialog?: boolean;
  productOverCosts: any;
  precio_Neto: any;
  takePayment: boolean;
  budgetId: string;
  productProvider: string;
  irisExtraSelected: string;
  watch: Function;
  isInsuredPolicyHolder: boolean;
  totalPriceAfterDiscount: number;
  totalPriceBeforeDiscount: number;
  additionalFeeDueElders: number;
  elderCount: number;
  elderAdditionalFeeStartingAge: number;
  infoExtra: any,
  sobrePrimaExtraIris?: any,
  currency?: any,
  axaId?: any
}

const ContractSummary: React.FC<ContractSummaryProps> = (props) => {
  const {
    filter,
    type,
    price,
    handleSubmit,
    productId,
    setProcessingTo,
    setCheckoutError,
    checkoutError,
    isDialog,
    productOverCosts,
    precio_Neto,
    takePayment,
    budgetId,
    productProvider,
    irisExtraSelected,
    totalPriceAfterDiscount,
    totalPriceBeforeDiscount,
    additionalFeeDueElders,
    elderCount,
    elderAdditionalFeeStartingAge,
    infoExtra,
    sobrePrimaExtraIris,
    currency,
    axaId
  } = props;

  const stripe: any = useStripe();
  const elements: any = useElements();
  const dispatch = useDispatch();
  const navigate = useNavigate();


  const [searchParams] = useSearchParams();
  const userTypeParams = searchParams.get("userType");

  const { code, collaborator: collaboratorCodeRedux }: any = useSelector<any>(({ Travelfine }) => Travelfine.code);
  const symbol = (code && currency) ? (currency?.symbol || code?.currency?.symbol) : '€'
  const { showPasswordModal }: any = useSelector<any>(
    ({ Travelfine }) => Travelfine.policy
  );
  const theme: any = useTheme();

  const [codeKey, setCodeKey] = useState(code?.codeKey);
  const [paymentStatus, setPaymentStatus] = useState(PAYMENT_STATUS.PAID);

  useEffect(() => {
    setCodeKey(code?.codeKey);
  }, [code?.codeKey]);

  let percentage = code?.percentage ?? 0;

  if (filter) {
    if (code?.groupType === TYPE_CODE.category) {
      const categoryValues = Array.isArray(code?.groupTypeValue)
        ? code.groupTypeValue
        : [code.groupTypeValue];

      const isCategoryCode = categoryValues.includes(filter?.modality?.value);

      percentage = isCategoryCode ? percentage : 0;
    }
  }

  const discountPercentage = percentage;
  const eldersHaveAdditionalFees = productProvider === PRODUCT_PROVIDERS.IMA;
  const priceWithoutAdditionalFees = Number(Number(price).toFixed(2));

  const onSubmit = async (data: any) => {
    try {
      let paymentIntentId;
      let client_secret;
      let paymentStatusToSend = PAYMENT_STATUS.ERROR_EMISSION;
      let amount: any = Number(totalPriceAfterDiscount).toFixed(2);
      amount = Number(amount);
      let multiplier = (currency?.value === 'JPY' || currency?.value === 'VND') ? 1 : 100;
      amount = Math.round(amount * multiplier);
      
      if (amount > 0) {
        if (!code || !code.isDeferredPayment || takePayment) {
          const billingDetails = {
            name: data.cardHolderName,
          };
          setProcessingTo(true);

          const cardElement = elements?.getElement(CardNumberElement);

          const url = createClientSecretUrl();
          const requestPayload = {
            amount,
            currency: currency?.value || 'EUR'
          };

          const response = await axiosInstance.post(url, requestPayload);
          const { client_secret: c_sec, paymentIntentId: p_Id } =
            response.data.data;
          paymentIntentId = p_Id;
          client_secret = c_sec;

          const paymentMethodReq = await stripe.createPaymentMethod({
            type: "card",
            card: cardElement,
            billing_details: billingDetails,
          });

          paymentStatusToSend = PAYMENT_STATUS.PAID;

          if (paymentMethodReq.error) {
            setCheckoutError(paymentMethodReq.error.message);
            setProcessingTo(false);
            setPaymentStatus(PAYMENT_STATUS.ERROR_PAYMENT);
            paymentStatusToSend = PAYMENT_STATUS.ERROR_PAYMENT;
            return;
          }

          if (paymentMethodReq?.paymentMethod?.id) {
            const stripeResponse = await stripe.confirmCardPayment(
              client_secret,
              {
                payment_method: paymentMethodReq.paymentMethod.id,
              }
            );

            const { error } = stripeResponse;

            if (error) {
              setCheckoutError(error.message);
              setProcessingTo(false);
              setPaymentStatus(PAYMENT_STATUS.ERROR_PAYMENT);
              paymentStatusToSend = PAYMENT_STATUS.ERROR_PAYMENT;
              // return;
            }
          }

          if (paymentStatusToSend === PAYMENT_STATUS.PAID) {
            setPaymentStatus(PAYMENT_STATUS.PAID);
          }
        }
        const {
          modality,
          origin,
          destination,
          departureDate,
          returnDate,
          totalTravellers,
          userInDestination,
        } = filter;

        const travellersData =
          data?.travellers?.length > 0
            ? data.travellers.map((traveller: any) => {
              return {
                ...traveller,
                documentNumber: traveller.documentNumber ?? "",
                documentType: traveller.documentNumber
                  ? traveller.documentType
                  : "",
                dob: format(new Date(traveller.dob), "yyyy-MM-dd"),
                irisExtraSelected: checkMinimunAge(format(new Date(traveller.dob), "yyyy-MM-dd"), departureDate) ? sobrePrimaExtraIris : irisExtraSelected,
              };
            })
            : [];

        const form = {
          user: {
            name: data.name,
            surname: data.surname,
            secondSurname: data?.secondSurname ?? '',
            dob: format(new Date(data.dob), "yyyy-MM-dd"),
            documentType: data.documentType,
            documentNumber: data.documentNumber,
            address: data.address,
            postalCode: data.postalCode,
            city: data.city,
            email: data.email,
            phoneNo: data.phoneNo,
            country: data.country,
            province: data.province,
            gender: data.gender ?? ''
          },
          isInsuredPolicyHolder: data.isInsuredPolicyHolder,
          // sameAddress: data.sameAddress,
          // destinationInsured: data.destinationInsured,
          confirmations: data.confirmations,
          travellers: travellersData,
          productId: productId,
          productVariant: type,
          precio_Neto: precio_Neto,
          price: totalPriceAfterDiscount?.toFixed(2),
          collaborator: code?.collaborator,
          code: code?._id,
          codeKey: code?.codeKey,
          stripePId: paymentIntentId,
          client_secret: client_secret,
          travelInfo: {
            category: modality,
            origin,
            destination,
            departureDate: format(new Date(departureDate), "yyyy-MM-dd"),
            returnDate: format(new Date(returnDate), "yyyy-MM-dd"),
            totalTravellers,
            userInDestination: userInDestination || false,
          },
          extras: productOverCosts,
          paymentStatus: paymentStatusToSend,
          collaboratorCreds: data.collaborator,
          discountPercentage,
          totalPrice: totalPriceBeforeDiscount?.toFixed(2),
          budgetId,
          productProvider,
          paymentMethod: "Stripe",
          irisExtraSelected: checkMinimunAge(format(new Date(data.dob), "yyyy-MM-dd"), departureDate) ? sobrePrimaExtraIris : irisExtraSelected,
          userType: userTypeParams,
          currency,
          axaId,
          isUsePercentage: percentage ? true : false,
        };
        dispatch(createPolicy(form, navigate));
      } else {
        toast.error("¡El precio no puede ser cero!");
      }
    } catch (err: any) {
      console.log("Error", err.message);
    }
    setProcessingTo(false);
  };

  const codeKeyChangeHandler = (event: any) => {
    setCodeKey(event.target.value);
  };

  const getCodeHandler = () => {
    if (codeKey) {
      if (code && code.collaborator) {
        if (!code.collaborator?.codes.some((key: any) => key === codeKey)) {
          toast.error("Este código de descuento no lo puedes utilizar.");
          return;
        }
      }

      if (collaboratorCodeRedux) {
        if (!code.collaborator?.codes.some((key: any) => key === codeKey)) {
          toast.error("Este código de descuento no lo puedes utilizar.");
          return;
        }
      }

      const {
        modality,
        origin,
        destination,
        departureDate,
        returnDate,
        totalTravellers
      } = filter;

      const visitData = {
        category: modality?.value,
        origin,
        destination,
        departureDate,
        returnDate,
        totalTravellers,
        productId,
        collaborator: collaboratorCodeRedux ? collaboratorCodeRedux._id : null
      };
      dispatch(getCollaboratorCodes({  codeKey, modality: filter?.modality?.value ?? null, visitData }));
    }
  };

  const modality = filter?.modality ?? null;
  const isModalityMexico = modality ? ((MID_CATEGOIRES.includes(modality?.key) || modality?.key === 'MultiviajeAnual') ? true : false) : false;

  return (
    <>
      <Paper
        elevation={3}
        className={`flex-col rounded-lg px-4 py-4 ${!isDialog && "hidden"
          } lg:flex`}
      >
        <p className="text-xl font-bold">
          <span className="" style={{ color: theme.palette.secondary.main }}>
            Resumen
          </span>{" "}
          Contrato
        </p>
        <div className="flex flex-col mt-2 text-sm divide-y-2">
          <div className="py-1">
            <p className="font-bold">
              {filter.modality.label} {type}
            </p>
          </div>
          <div className="py-1">
            <p className="font-bold">{!isModalityMexico ? 'Modalidad' : 'Tipo de Plan'}</p>
            <p>{filter.modality.label}</p>
          </div>
          <div className="py-1">
            <p className="font-bold">Destino</p>
            <p className="whitespace-nowrap">
              País de origen: {filter.origin.value}
            </p>
            <p className="whitespace-nowrap">
              País de destino: {filter.destination.value}
            </p>
          </div>
          <div className="py-1">
            <p className="font-bold">Fechas</p>
            <p>Salida: {getFormattedDate(filter?.departureDate)}</p>
            <p>Regreso: {getFormattedDate(filter?.returnDate)}</p>
          </div>
          <div className="py-1">
            <p className="font-bold">N de Asegurados</p>
            <p>{filter.totalTravellers}</p>
          </div>

          <div className="py-1">
            <p className="font-bold">Promociones</p>
            <p>Introduce tu código</p>
            <div className="flex justify-between w-full p-1 mt-1 border border-black rounded-md">
              <input
                value={(codeKey?.toLowerCase().includes(CODE_TRAVEL_FINE_HOME_PRO) || codeKey?.toLowerCase().includes(CODE_TRAVEL_FINE_HOME_PRO)) ? '' : codeKey}
                onChange={codeKeyChangeHandler}
                className="outline-none"
              />
              <div
                className="p-1 ml-1 rounded-md cursor-pointer"
                style={{ backgroundColor: theme.palette.secondary.main }}
                onClick={getCodeHandler}
              >
                <ArrowForwardIcon />
              </div>
            </div>
            {eldersHaveAdditionalFees || productProvider === PRODUCT_PROVIDERS.IRIS ? (
              <PriceWithAdditionalFees
                elderAdditionalFeeStartingAge={elderAdditionalFeeStartingAge}
                additionalFeeDueElders={additionalFeeDueElders}
                elderCount={elderCount}
                priceWithoutAdditionalFees={priceWithoutAdditionalFees}
                infoExtra={infoExtra ?? null}
                currency={symbol ?? '€'}
              />
            ) : null}

            <div className="flex justify-between mt-2">
              <p>Total</p>
              {discountPercentage ? (
                <p className="font-bold">
                  <span className="mr-2 line-through">
                    {`${Number.parseFloat(totalPriceBeforeDiscount.toString()).toFixed(2)} ${symbol ?? '€'}`}
                  </span>
                  {numberWithCommas(totalPriceAfterDiscount)} {symbol ?? '€'}
                </p>
              ) : (
                <p className="font-bold">
                  {numberWithCommas(totalPriceAfterDiscount)} {symbol ?? '€'}
                </p>
              )}
            </div>

            <Button
              color="secondary"
              className="w-full mt-2"
              disabled={!price}
              onClick={handleSubmit(onSubmit)}
            >
              Pagar
            </Button>
            <p className="mt-1 text-center text-red-600">{checkoutError}</p>
          </div>
        </div>
      </Paper>
      {!isDialog && (
        <Button
          color="secondary"
          className="w-full mt-2 lg:hidden"
          disabled={!price}
          onClick={handleSubmit(onSubmit)}
        >
          Confirmar la compra
        </Button>
      )}
      <CreatePassword
        filter={filter}
        paymentStatus={paymentStatus}
        open={showPasswordModal}
        onClose={() => { }}
        productInfo={{
          productProvider,
          productVariant: type,
          productId,
          codeKey: code?.codeKey,
          paymentMethod: "Stripe",
        }}
      />
    </>
  );
};

function PriceWithAdditionalFees({
  priceWithoutAdditionalFees,
  elderCount,
  additionalFeeDueElders,
  elderAdditionalFeeStartingAge,
  infoExtra,
  currency
}: {
  priceWithoutAdditionalFees: number;
  elderCount: number;
  additionalFeeDueElders: number;
  elderAdditionalFeeStartingAge: number;
  infoExtra?: any;
  currency?: any;
}) {

  const info = infoExtra ? infoExtra : ` Mayores de ${elderAdditionalFeeStartingAge}`
  return (
    <div className="flex flex-col mt-2">
      {elderCount > 0 ? (
        <div className="flex flex-col space-y-2">
          <div className="flex justify-between">
            <p>Precio</p>
            <p className="font-bold">{priceWithoutAdditionalFees} {currency ?? '€'}</p>
          </div>
          <div className="flex flex-col space-y-0.5">
            <p className="font-bold">
              {info}
            </p>
            <div className="flex justify-between">
              <p>Cantidad: {elderCount}</p>
              <p className="font-bold">+ {additionalFeeDueElders.toFixed(2)} {currency ?? '€'}</p>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
}

export default ContractSummary;
